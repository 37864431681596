<template>
  <section id="anasayfa" class="bg-light">
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-12">
        </div>
      </div>
      <div class="row">

        <!--    <div class="container">-->

        <!--      <img src="images/anaresim.JPG" style="width: 1200px ; height: 550px"-->
        <!--           alt="Prof.Dr. Hasan Hilmi Hacisalihoğlu">-->

        <!--    </div>-->
        <VueSlickCarousel :arrows="true" v-if="getSliders.length" ref="carousel" :dots="true" :autoplay="true" :adaptiveHeight="true">
          <div v-for="slider in getSliders" :key="slider.id">
            <img :src="slider.resim" width="1200" height="800"
                 alt="Prof.Dr. Hasan Hilmi Hacisalihoğlu">
          </div>

        </VueSlickCarousel>

      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "mycoursel",
  computed: {
    getSliders()  {
      return this.$store.getters.getSliders;
    }
  },
  components: {
    VueSlickCarousel
  }
}
</script>

<style scoped>

</style>
